<template>
  <div
    class="container order-search"
    style="margin-top: 6.5vh; padding-left: 2vw; padding-right: 2vw">
    <h2>Template Filters</h2>
    <hr />
    <b-card>
      <b-card-title>
        <span
          @click="filter.basicFieldsExpaned = !filter.basicFieldsExpaned"
          class="pointer"
          :aria-expanded="true"
          aria-controls="collapse-basic"
        >
        Basic Search Fields
        <i :class="[
          filter.basicFieldsExpaned
            ? 'fas fa-angle-up'
            : 'fas fa-angle-down',
            'pl-2'
          ]"></i>
        </span>
      </b-card-title>

      <b-collapse id="collapse-basic" v-model="filter.basicFieldsExpaned">
        <b-form-row class="mb-3">
          <b-col cols="12">
            <label for="txtTemplateName">Template Name</label>
            <b-form-textarea
              id="txtTemplateName"
              v-model="filter.templateName">
            </b-form-textarea>
          </b-col>
        </b-form-row>
        <div class="form-row">
          <div class="col-md-12">
                <label for="inputEmail4">Delivery Site</label>
                <tf-consignee-multi-select
                  :customerId="loggedInUserCustomerId"
                  :userId="loggedInUserId"
                  includeReadOnly="true">
                </tf-consignee-multi-select>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-card>
      <b-card-title>
        <span @click="filter.advancedFieldsExpaned = !filter.advancedFieldsExpaned"
            :aria-expanded="false"
            aria-controls="collapse-advanced"
            class="pointer">
        Advanced Search Fields
        <i :class="[
              filter.advancedFieldsExpaned
                ? 'fas fa-angle-up'
                : 'fas fa-angle-down',
              'pl-2'
            ]"></i>
      </span>
    </b-card-title>
    <b-collapse id="collapse-advanced" v-model="filter.advancedFieldsExpaned">
      <div class="form-row">
        <div class=" col-md-6">
          <label for="inputPassword4">BillTo</label>

          <tf-bill-to-multi-select :customerId="loggedInUserCustomerId"
                                   :userId="loggedInUserId"></tf-bill-to-multi-select>
        </div>
        <div class=" col-md-6">
          <label for="inputPassword4">Account Of</label>

          <tf-account-of-multi-select :customerId="loggedInUserCustomerId"
                                      :userId="loggedInUserId"></tf-account-of-multi-select>
        </div>

      </div>

      <div class="form-row">
        <div class=" col-md-6">
          <label for="inputPassword4">Shipper</label>

          <tf-shipper-multi-select :customerId="loggedInUserCustomerId"
                                   :userId="loggedInUserId"></tf-shipper-multi-select>
        </div>
        <div class=" col-md-6">
          <label for="inputPassword4">Supplier</label>

          <tf-supplier-multi-select :customerId="loggedInUserCustomerId"
                                    :userId="loggedInUserId"></tf-supplier-multi-select>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-6">
          <label for="inputPassword4">Delivery City</label>
          <tf-cities-multi-select></tf-cities-multi-select>
        </div>

        <div class="col-md-6">
          <label for="inputEmail4">Delivery State</label>
          <tf-states-multi-select></tf-states-multi-select>
        </div>
      </div>

        <div class="form-row">
          <div class="col-md-6">
            <label for="inputPassword4">Commodity Class</label>
            <tf-commodity-class-multi-select
              :customerId="loggedInUserCustomerId"
              :userId="loggedInUserId">
            </tf-commodity-class-multi-select>
          </div>
        </div>
      </b-collapse>
    </b-card>
    <b-card>
      <b-card-title>
        <span
          @click="filter.datesExpanded = !filter.datesExpanded"
          :aria-expanded="false"
          aria-controls="collapse-dates"
          class="pointer">
          Advanced Date Fields
          <i :class="[
            filter.datesExpanded ? 'fas fa-angle-up' : 'fas fa-angle-down',
            'pl-2'
            ]">
          </i>
        </span>
      </b-card-title>

      <b-collapse id="collapse-dates" v-model="filter.datesExpanded">
        <div class="form-row">
          <b-col md="6">
            <label for="inputPassword4">Created From</label>
            <tf-date-picker
              id="filterCreatedDateFrom"
              :selectedDate="filter.createdFrom"
              @onChange="filter.createdFrom = $event">
            </tf-date-picker>
          </b-col>
          <b-col md="6">
            <label for="inputPassword4">Created To</label>
            <tf-date-picker
              id="filterCreatedDateTo"
              :selectedDate="filter.createdTo"
              @onChange="filter.createdTo = $event">
            </tf-date-picker>
          </b-col>
        </div>
      </b-collapse>
    </b-card>

    <div class="form-row">
      <b-col>
        <b-button
          @click="applyTemplateFilters()"
          variant="primary"
          class="mr-3">        
          Apply Filters
        </b-button>
        <b-button
          @click="clearTemplateFilters()"
          variant="outline-danger"
          class="mr-5">
          Clear Filters
        </b-button>
      </b-col>
    </div>
    <b-row v-if="filterError" class="text-danger justify-content-center">
      An error unexpected error has occurred.
      <br />Please try again later or contact support.
    </b-row>
  </div>
</template>

<script>

  import TfDatePicker from "@/components/order-tender/FilterDatePicker";
  import { mapActions, mapState, mapGetters } from "vuex";
  import Multiselect from "vue-multiselect";

  import TfBillToMultiSelect from "@/components/users/BillToMultiSelect";
  import TfAccountOfMultiSelect from "@/components/users/AccountOfMultiSelect";
  import TfSupplierMultiSelect from "@/components/users/SupplierMultiSelect";
  import TfShipperMultiSelect from "@/components/users/ShipperMultiSelect";

  import TfConsigneeMultiSelect from "@/components/users/ConsigneeMultiSelect";
  import TfCitiesMultiSelect from "@/components/multiselects/CitiesMultiSelect";
  import TfStatesMultiSelect from "@/components/multiselects/StatesMultiSelect";
  import TfCommodityClassMultiSelect from "@/components/multiselects/CommodityClassMultiSelect";


  export default {
    name: "AdvanceTemplateFilters",
    components: {
      TfDatePicker,
      Multiselect,
      TfBillToMultiSelect,
      TfSupplierMultiSelect,
      TfShipperMultiSelect,
      TfAccountOfMultiSelect,
      TfConsigneeMultiSelect,
      TfCitiesMultiSelect,
      TfStatesMultiSelect,
      TfCommodityClassMultiSelect

    },
    data: () => {
      const d = new Date();
      return {
        filterError: false,
        filter: {
          templateName: null,
          createdFrom: null,
          createdTo: null,
          selectedBillTos: [],
          selectedShippers: [],
          selectedSuppliers: [],
          selectedAccountOfs: [],
          selectedConsignees: [],
          selectedCities: [],
          selectedStates: [],
          selectedCommodityClasses: [],
          datesExpanded: false,
          advancedFieldsExpaned: false,
          basicFieldsExpaned: true
        },

        options: {
          format: "MM/DD/YYYY",
          useCurrent: false,
          showClear: true,
          showClose: true
        },


      };
    },
    mounted() {
      //load the objects back from state.

      this.filter = { ...this.filterObject };
      this.setSelectedBillTos(this.filter.selectedBillTos);
      this.setSelectedConsignees(this.filter.selectedConsignees);

      this.setSelectedShippers(this.filter.selectedShippers);
      this.setSelectedSuppliers(this.filter.selectedSuppliers);
      this.setSelectedAccountOfs(this.filter.selectedAccountOfs);
      this.setSelectedCities(this.filter.selectedCities);
      this.setSelectedStates(this.filter.selectedStates);
      this.setSelectedCommodityClasses(this.filter.selectedCommodityClasses);
    },

    methods: {
      ...mapActions("orderTemplate", [
        "setTemplateFilters",
        "setIsFilterApplied",
        "setPagination"
      ]),
      ...mapActions("companyMultiSelects", [
        "setSelectedBillTos",
        "setSelectedShippers",
        "setSelectedSuppliers",
        "setSelectedAccountOfs",
        "setSelectedConsignees",
        "setSelectedCities",
        "setSelectedStates",
        "setSelectedCommodityClasses",

      ]),
      async applyTemplateFilters() {
        this.setPagination(1);

        this.setTemplateFilters(this.filter);
        this.setIsFilterApplied(true);
        this.$emit("closePanel", true); //true here will trigger reload of orders.
      },
      async clearTemplateFilters() {

        this.setPagination(1);

        this.filter = {
          templateName: null,
          createdFrom: null,
          createdTo: null,
          selectedBillTos: [],
          selectedShippers: [],
          selectedSuppliers: [],
          selectedAccountOfs: [],
          selectedConsignees: [],
          selectedCities: [],
          selectedStates: [],
          selectedCommodityClasses: [],
          datesExpanded: false,
          advancedFieldsExpaned: false,
          basicFieldsExpaned: true
        };


        this.setTemplateFilters(this.filter);
        this.setIsFilterApplied(false);

        this.setSelectedBillTos([]);
        this.setSelectedShippers([]);
        this.setSelectedSuppliers([]);
        this.setSelectedAccountOfs([]);
        this.setSelectedConsignees([]);
        this.setSelectedCities([]);
        this.setSelectedStates([]);
        this.setSelectedCommodityClasses([]);

        this.$emit("closePanel", true); //true here will trigger reload of orders.
      },
    },
    computed: {
      ...mapGetters("oidcStore", ["oidcUser"]),
      ...mapGetters("user", ["loggedInUser"]),
      ...mapState("orderTemplate", ["isFilterApplied", "filterObject"],),
      ...mapState("companyMultiSelects", [
        "selectedCities",
        "selectedStates",
        "selectedBillTos",
        "selectedShippers",
        "selectedSuppliers",
        "selectedAccountOfs",
        "selectedConsignees",
        "selectedCommodityClasses",
      ]),

      //   filterBasicSearchTerm: {
      //     get() { return this.$store.state.orderTemplate.searchTerm },
      //     set(value) { this.setSearchTerm(value) },

   // },

    orderRefNumberPopoverText() {
      return `<p>You can search for one or many order number or reference number in this box.</p>
            <p>You can separate order/reference numbers by spaces, commas, semicolons, ampersands, percent signs or underscores.</p>`;
    },
    orderFilterPopoverText() {
      return `<p>You can filter by any or all of the fields below.</p>
            <p>If no date ranges are specified the default delivery range will be set to 1 year prior to and 1 month after today.</p>`;
    },

    loggedInUserId() {
      return this.oidcUser.sub;
    },

    loggedInUserCustomerId() {
      return this.loggedInUser.customerId;
    },
  },
  watch: {
      selectedBillTos(newValue) {
        // can also pass in oldValue
      this.filter.selectedBillTos = newValue;
      },
      selectedConsignees(newValue) {
        this.filter.selectedConsignees = newValue;
      },
      selectedShippers(newValue) {
        this.filter.selectedShippers = newValue;
      },
      selectedSuppliers(newValue) {
        this.filter.selectedSuppliers = newValue;
      },
      selectedAccountOfs(newValue) {
        this.filter.selectedAccountOfs = newValue;
      },
      selectedCities(newValue) {
        this.filter.selectedCities = newValue;
      },
      selectedStates(newValue) {
        this.filter.selectedStates = newValue;
      },
      selectedCommodityClasses(newValue) {
        this.filter.selectedCommodityClasses = newValue;
      }
    }
};
</script>

<style scoped>
  .dropdown-row {
    padding: 7.5px 0 7.5px 0;
  }

  .status-dropdown-row {
    padding: 7.5px 0 7.5px 20px;
  }

  .date-dropdown-row {
    padding: 0px 0 0 20px;
    /* max-width: 220px; */
  }

  .bottom-border {
    border-bottom: lightgrey solid 1px;
  }

  .top-border {
    border-top: lightgrey solid 1px;
  }

  .order-search .multiselect__select {
    height: 35px;
  }

  .order-search .multiselect__tags {
    min-height: 35px !important;
    padding: 4px 40px 0 8px !important;
  }

  .order-search .multiselect__placeholder {
    margin-bottom: 6px;
  }

  .order-search .multiselect__tag {
    margin-bottom: 0px;
  }
  .pointer {
    cursor: pointer;
  }
</style>
